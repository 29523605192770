// Types
import Company from 'types/company';
import { GTagCategory } from 'types/googleAnalytics';
const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);

const TelNumber: React.FC = (): React.ReactElement => {
  return (
    <a
      className="font-bold"
      onClick={() => {
        ga(
          'send',
          'event',
          GTagCategory.Footer,
          'Contact',
          companyID === Company.Ironmongery ? '03003 038 821' : '03003 038 826'
        );
      }}
      href={companyID === Company.Ironmongery ? 'tel:03003 038 821' : 'tel:03003 038 826'}
    >
      {companyID === Company.Ironmongery ? '0300 303 88 21' : '0300 303 88 26'}
    </a>
  );
};

export default TelNumber;
