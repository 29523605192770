export enum GTagCategory {
  Footer = 'Footer',
  Header = 'Header',
  Menu = 'Menu',
  Category = 'Category',
  Detail = 'Detail',
  AddToBasket = 'Add-to-Basket',
  Homepage = 'Homepage',
  PageInteraction = 'Page Interaction',
  Cards = 'Cards',
  Account = 'Account',
  Breadcrumbs = 'Breadcrumbs',
  Basket = 'Basket',
  Checkout = 'Checkout',
  Information = 'Information',
  Behaviour = 'Behaviour',
  CopiedText = 'Copied Text',
  PastedText = 'Pasted Text',
  GoogleShoppingLandingPage = 'Google Shopping Landing Page',
  RevenueRisk = 'Revenue Risk',
  ProductRecommendation = 'Product Recommendation',
  Facets = 'Facets',
  Search = 'Search',
  ZeroResultsRefinement = 'Zero Results Refinement',
  ReferAMate = 'Refer a Mate',
  CampaignPage = 'Campaign Page',
  VariantsSelect = 'Variants Select',
  VariantsButton = 'Variants Button',
  CatalogueRequest = 'Catalogue Request',
  CustomerType = 'Customer Type',
  Delivery = 'Delivery',
  Returns = 'Returns',
  Slider = 'Catalogue Slider',
}
