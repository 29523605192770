import { merge } from 'lodash';
import { createAction, createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from 'src/redux/store';
import { AddressResponse, CreateAddress } from 'types/address';
import { OrderSummaryResponse } from 'types/orderSummary';
import axios, { AxiosError } from 'axios';
import Company from 'types/company';
import AccountTypes from 'types/account';
import getCookies from '@utils/getCookies';

interface UserState {
  orderSummary: OrderSummaryResponse | [];
  addresses: AddressResponse[] | [];
  cardCode: string;
  credits: AccountTypes['credits'] | null;
  details: AccountTypes['accountDetails'] | null;
  isDetailsLoading: boolean;
}

const initialState: UserState = {
  orderSummary: [],
  addresses: [],
  cardCode: '',
  credits: null,
  details: null,
  isDetailsLoading: false,
};

const hydrate = createAction<RootState>(HYDRATE);

export const createAddress = createAsyncThunk(
  'user/createAddress',
  async (payload: { address: CreateAddress; token: string }, { rejectWithValue }) => {
    try {
      const { status, data: response } = await axios.post('/api/createAddress', {
        address: payload.address,
        token: payload.token,
      });
      const returnedAddress: AddressResponse = response.data;
      if (status === 200 && returnedAddress) {
        return returnedAddress;
      } else {
        throw 'Creating Address Failed';
      }
    } catch (err: any) {
      const error: AxiosError = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAddress = createAsyncThunk(
  'user/updateAddress',
  async (payload: { address: AddressResponse; token: string }, { rejectWithValue }) => {
    try {
      const { status, data: response } = await axios.post('/api/updateAddress', {
        address: payload.address,
        token: payload.token,
      });
      if (status === 200 && response.data) {
        return response.data;
      } else {
        throw 'Updating Address Failed';
      }
    } catch (err: any) {
      const error: AxiosError = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAddress = createAsyncThunk(
  'user/removeAddress',
  async (payload: { id: string; token: string }, { rejectWithValue }) => {
    try {
      const { id, token } = payload;
      const { data, status }: { data: { addressRemoved: string }; status: number } =
        await axios.post('/api/removeAddress', {
          id,
          token,
        });
      if (status === 200 && data.addressRemoved) {
        return data.addressRemoved;
      } else {
        throw 'Removing Address Failed';
      }
    } catch (err: any) {
      const error: AxiosError = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const setCardCode = createAsyncThunk(
  'user/setCardCode',
  async (param: {
    companyID: Company;
    baseUrl: string | undefined;
    apiKey: string | undefined;
    token: string;
  }) => {
    const { baseUrl, apiKey, token } = param;
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/account/GetCardCode`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
        Authorization: token,
      },
    });
    return response.data;
  }
);

export const setCredits = createAsyncThunk(
  'user/setCredits',
  async (param: {
    companyID: Company;
    baseUrl: string | undefined;
    apiKey: string | undefined;
    cardCode: string;
  }) => {
    const { baseUrl, apiKey } = param;
    const token = getCookies('authToken');
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/account/Credit`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const setAccountDetails = createAsyncThunk(
  'user/setAccountDetails',
  async (param: {
    companyID: Company;
    baseUrl: string | undefined;
    apiKey: string | undefined;
    email: string;
    cardCode: string;
    userHasCookies?: boolean;
  }) => {
    const { baseUrl, apiKey } = param;
    const token = getCookies('authToken');
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/account/ContactDetails`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAddresses: (state, action: PayloadAction<AddressResponse[]>) => {
      state.addresses = action.payload;
    },
    setOrderSummary: (state, action: PayloadAction<OrderSummaryResponse>) => {
      state.orderSummary = action.payload;
    },
    resetCardCode: (state) => {
      state.cardCode = '';
    },
    resetUserCredits: (state) => {
      state.credits = null;
    },
    resetUserDetails: (state) => {
      state.details = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(removeAddress.fulfilled, (state, action: PayloadAction<string>) => {
        const updatedAddresses = state.addresses.filter(
          (addresses) => addresses.id !== action.payload
        );
        state.addresses = updatedAddresses;
      })
      .addCase(createAddress.fulfilled, (state, action: PayloadAction<AddressResponse>) => {
        const newAddresses: AddressResponse[] = state.addresses;
        newAddresses.unshift(action.payload);
        state.addresses = newAddresses;
      })
      .addCase(updateAddress.fulfilled, (state, action: PayloadAction<AddressResponse>) => {
        const { payload } = action;

        const existingAddress = state.addresses.find((address) => address.id === payload.id);
        if (existingAddress) {
          const mergedAddress = merge(existingAddress, payload);
          const oldAddressIndex = state.addresses.findIndex(
            (address) => address.id === mergedAddress?.id
          );
          state.addresses[oldAddressIndex] = mergedAddress;
        }
      })
      .addCase(hydrate, (state, action) => {
        return {
          ...state,
          ...action.payload[userSlice.name],
        };
      })
      .addCase(setCardCode.fulfilled, (state, action) => {
        // Add user to the state array
        state.cardCode = action.payload;
      })
      .addCase(setCredits.fulfilled, (state, action) => {
        state.credits = action.payload;
      })
      .addCase(setAccountDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(setAccountDetails.fulfilled, (state, action) => {
        state.details = action.payload;
        state.isDetailsLoading = false;
      });
  },
});

export const { setAddresses, setOrderSummary, resetCardCode, resetUserCredits, resetUserDetails } =
  userSlice.actions;

export const selectOrderSummary = (state: RootState): OrderSummaryResponse =>
  state.user.orderSummary;

export const selectAddresses = (state: RootState): AddressResponse[] => state.user.addresses;

export const selectAddressById = (state: RootState, addressID: string): AddressResponse =>
  state.user.addresses.find((address: AddressResponse) => address.id === addressID);

export const selectUserDetails = (state: RootState): AccountTypes['accountDetails'] =>
  state.user.details;

export default userSlice.reducer;
